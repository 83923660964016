import { CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../App.css';
import MyComponent from '../components/MyComponent';
import Error from './Error';
function Results() {

  const queryParameters = new URLSearchParams(window.location.search)

  const id = queryParameters.get("id")

  const language = queryParameters.get("language")

  //console.log(id)
  const [data, setData] = useState([]);
  const [loadingText, setLoadingText] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  useEffect(() => {

    if (language === "en") {
      setLoadingText("Loading...");
    } else {
      setLoadingText("Bezig met laden...");
    }

    fetch(`${process.env.React_App_FETCH_DATA_API}/?id=${id}`)
      .then(response => response.json())
      .then((response) => {

        console.log(response['pageTranslationText'][0]);

        setData({
          data: response
        });

        setLoading(false);

      }).catch(error => {

        setLoading(false);
        setIsError(true);
        console.error(error)

      });


  }, []);

  return (
    <div className="App">
      {loading ? (

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress color="secondary" />

          <Typography variant="p" sx={{ fontWeight: "bold", fontSize: 28 }} align="left" gutterBottom>
            {loadingText}
          </Typography>
        </div>

      ) : isError ? <Error /> :
        <MyComponent data={data} />}
    </div>
  );
}

export default Results;
