import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { motion } from 'framer-motion';
import React from 'react';
import CountingAnimation from '../components/CountingAnimation';
import ImageComponent from '../components/ImageComponent';
import ProgressRow from '../components/ProgressBarRow';
const useStyles = makeStyles({
    root: {
        position: 'relative',
    },
    covered: {
        color: '#ff5722',
    },
    uncovered: {
        color: '#e0e0e0',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});


function ScoreComponent(props) {

    const { message, data } = props.data;

    const safetyScore = data.safetyScore;

    const subScore = data.subScore;

    //console.log(safetyScore.message);

    var reactionLevelColor;
    if (safetyScore.reactionLevel === "ReactionLow") {
        reactionLevelColor = '#FF0000';
    } else if (safetyScore.reactionLevel === "ReactionMed") {
        reactionLevelColor = '#3B82F6';
    } else if (safetyScore.reactionLevel === "ReactionHigh") {
        reactionLevelColor = '#37b848';
    } else {
        reactionLevelColor = '#37b848';
    }
    return (
        <Box sx={{


        }}>

            <Grid container justifyContent="center" sx={{ minHeight: '80vh' }}>
                <Grid item xs={12} sm={10} md={8} lg={6}>

                    <motion.div
                        initial={{ scale: 0.2, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.7 }}
                        style={{ perspective: "100px", perspectiveOrigin: "50% 50%", transformStyle: "preserve-3d" }}
                    >
                        <Box
                            sx={{
                                minHeight: "65vh",
                                backgroundColor: 'white',
                                color: '#003d86',
                                boxShadow: '0px 0px 1px rgba(185, 185, 185, 0.5)', // Set box-shadow to red
                                //padding: 5,
                                display: 'table-cell',
                                fontFamily: 'Times',
                            }}
                        >

                            {/* <Box sx={{
                            backgroundColor: '#ff9640',

                            boxShadow: '0px 0px 1px rgba(185, 185, 185, 0.5)', // Set box-shadow to red
                            padding: 2,

                        }}>
                            <Typography variant="h5" sx={{ fontSize: 25, fontWeight: 'bold', color: '#ffffff', textAlign: 'center' }} gutterBottom>
                                Inconnect Quick Scan Results
                            </Typography>
                        </Box> */}

                            <Box sx={{
                                padding: 5,
                            }}>
                                <Typography variant="h5" sx={{ fontSize: 20, fontWeight: 'bold', color: 'var(--title-color)' }} gutterBottom>
                                    {data.pageTranslationText[0].your_safety_score}
                                </Typography>

                                <Box height={20} />

                                <center>
                                    <CountingAnimation end={safetyScore.scoreNum} />
                                </center>

                                <Box height={40} />

                                {/* <Typography variant="h5" sx={{ fontSize: 18, fontWeight: 'bold', color: reactionLevelColor }} align="left" gutterBottom>
                                    {safetyScore.message}
                                </Typography> */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <img src={image} alt="Something Went Wrong" width='60px' align="center" loop={false} /> */}
                                    <Typography
                                        variant="h5"
                                        sx={{ fontSize: 18, fontWeight: 'bold', color: reactionLevelColor }}
                                        align="left"
                                        gutterBottom
                                    >
                                        {safetyScore.message}
                                    </Typography>
                                </div>

                                <Box height={40} />

                                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'var(--title-color)' }} align="left" gutterBottom>
                                    {data.pageTranslationText[0].safety_score_mean}
                                </Typography>

                                <Box height={20} />

                                <Typography variant="p" sx={{ textAlign: 'left', color: 'var(--desc-color)' }} align="left" gutterBottom>
                                    <ImageComponent />    {data.profile}
                                </Typography>

                                <Box height={25} />

                                <Grid container spacing={2}>

                                    {data.subScore.map(item => (
                                        <Grid key={item.type} item xs={12} sm={12}>
                                            <ProgressRow percentage={item.score} type={item.type} />
                                        </Grid>
                                    ))}

                                </Grid>
                            </Box>

                            <Box height={40} />
                        </Box>
                    </motion.div>
                </Grid>
            </Grid>
        </Box >
    );
}

export default ScoreComponent;
