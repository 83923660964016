import { Box, Button, Typography } from '@mui/material';
import gif from '../assets/error.gif';
import Header from '../components/Header';
function Error() {

    return (<div>

        <Header />
        <center>  <div>
            <Box height={80} />
            <Typography variant="h1" sx={{ fontWeight: "bold", fontSize: 40 }} align="center" gutterBottom>
                Oops!...
            </Typography>
            <img src={gif} alt="Something Went Wrong" />
            <Typography variant="h1" sx={{ fontSize: 20 }} align="center">
                Something went wrong Please try again later
            </Typography>
            <Box height={50} />
            {/* <Button
                onClick={() => {
                    window.open('http://inconnect.ddns.net.s3-website.eu-central-1.amazonaws.com/', '_blank');
                }}
                variant="contained" color="primary">
                Retry
            </Button> */}
        </div></center>
    </div>);

}

export default Error
