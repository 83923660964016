import { Box, Button, Grid } from '@mui/material';
import * as React from 'react';
const BottomButtons = ({ onNextClick, onPrevClick, isNextDisplay, isPrevDisplay, nextBtnText, prevBtnText }) => {

    var prevButton = isPrevDisplay ?
        <Button
            variant="contained"
            color="primary"
            sx={{ backgroundColor: 'var(--primary-color)', color: 'white', minWidth: '160px' }}
            onMouseOver={(e) => e.target.style.backgroundColor = 'var(--primary-color)'}
            onClick={onPrevClick}
        >
            {prevBtnText}
        </Button>
        : <Box />;

    var nextButton = isNextDisplay ? (<Button
        variant="contained"
        sx={{ backgroundColor: 'var(--primary-color)', color: 'white', minWidth: '160px' }}
        onMouseOver={(e) => e.target.style.backgroundColor = 'var(--primary-color)'}

        onClick={onNextClick}
    >
        {nextBtnText}
    </Button>) : (<Box />);

    return (
        <Grid >
            {/* container justifyContent="center" */}
            <Grid item xs={12} sm={10} md={8} lg={6}>
                <Box

                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: 2,
                        // marginLeft: 2,
                        // marginRight: 2,
                        paddingBottom: 2,
                        bgcolor: 'background.paper',
                        borderTop: 1,
                        borderColor: 'divider',
                        zIndex: 1000,
                    }}
                >
                    {
                        prevButton
                    }

                    {isNextDisplay && isPrevDisplay ? <Box width="20%" /> : <Box />}


                    {
                        nextButton
                    }



                </Box>
            </Grid>
        </Grid>
    );
};

export default BottomButtons;
