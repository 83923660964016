import { Box, Typography } from '@mui/material';
import React from 'react';

import ImageComponent from '../components/ImageComponent.js';

function ProductTipsComponent(props) {

    const { tip, whyHeader, whyDesc, whichHeader, whichDesc } = props.data;

    console.log(whyDesc)
    var text = whichDesc.split(/\n\s*(?:•|-|■-|■| ■ |•|●|)\s+/);

    return (
        <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold", color: 'var(--title-color)' }} align="left" gutterBottom>
                {tip}
            </Typography>
            <Box height={20} />
            <Typography variant="h5" sx={{ fontWeight: "bold", fontSize: 18, color: 'var(--title-color)' }} align="left" gutterBottom>
                {whyHeader === "" ? "" : <ImageComponent />}  {whyHeader}
            </Typography>
            <Box height={30} />
            <Typography variant="p" sx={{ color: 'var(--desc-color)' }} align="left" gutterBottom>

                {whyDesc}

            </Typography>

            <Box height={30} />
            <Typography variant="h5" sx={{ fontWeight: "bold", fontSize: 18, color: 'var(--title-color)' }} align="left" gutterBottom>
                {whichHeader === "" ? "" : <ImageComponent />}   {whichHeader}

            </Typography>

            {/* 
            <Typography variant="p" align="left" textAlign="start" sx={{ color: 'var(--desc-color)' }} gutterBottom>

                {text.map((bulletPoint, index) => {

                    var TipsText = bulletPoint.split(/Tip →|→ Tip:/);

                    if (TipsText.length > 1) {
                        return TipsText.map((subBullet, subIndex) => (
                            subIndex ===  0 ? <p key={subIndex}> • {subBullet}<br /></p> : <p key={subIndex}> Tip → <br /> {subBullet}<br /></p>
                        ));
                    } else {
                        return index ===  0 ? <p key={index}>{bulletPoint}<br /></p> : <p key={index}>• {bulletPoint}<br /></p>;
                    }
                })}
            </Typography> */}

            <Typography variant="p" align="left" textAlign="start" sx={{ color: 'var(--desc-color)' }} gutterBottom>

                {text.map((bulletPoint, index) => {

                    var TipsText = bulletPoint.split(/Tip →|→ Tip:/);

                    if (TipsText.length > 1) {
                        return TipsText.map((subBullet, subIndex) => (
                            subIndex === 0 ? <p key={subIndex}> • {subBullet}<br /></p> : <p key={subIndex}> Tip → <br /> {subBullet}<br /></p>
                        ));
                    } else {
                        return index === 0 ? <p key={index}>{bulletPoint}<br /></p> : <p key={index}>• {bulletPoint}<br /></p>;
                    }
                })}
            </Typography>

            {/* <Typography variant="p" align="left" textAlign="start" sx={{ color: 'var(--desc-color)' }} gutterBottom>
                {text.map((bulletPoint, index) => (
                    <div key={index}>
                        {bulletPoint.split(/\n\s*(?:•|-|■-|■)\s+/).map((subBulletPoint, subIndex) => (
                            <p key={subIndex}>• {bulletPoint}<br /></p>
                        ))}
                    </div>
                ))}
            </Typography> */}

            {/* <Typography variant="p" align="left" textAlign="start" sx={{ color: 'var(--desc-color)' }} gutterBottom>

                {text.map((bulletPoint, index) => (

                    <p key={index}>• {bulletPoint}
                        <br />
                    </p>
                ))}
            </Typography> */}
        </Box>
    );
}

export default ProductTipsComponent;
