import { Box, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import image from '../assets/completed-image.png';
import '../index';
function Thanks(props) {
    const { message, data } = props.data;


    return (

        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '80vh' }}>
            <Grid item xs={12} sm={10} md={8} lg={6}>
                <motion.div
                    initial={{ scale: 0.2, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.7 }}
                    style={{ perspective: "100px", perspectiveOrigin: "50% 50%", transformStyle: "preserve-3d" }}
                >
                    <center>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                boxShadow: '0px 0px 1px rgba(185, 185, 185, 0.5)', // Set box-shadow to red
                                minHeight: "65vh",
                                padding: 5,
                                fontFamily: 'Times',
                                textAlign: 'left',
                                display: 'table-cell'
                            }}
                        >


                            <Typography variant="h1" sx={{ fontWeight: "bold", fontSize: 20, color: 'var(--title-color)' }} align="center" gutterBottom>
                                {data.pageTranslationText[0].congratulations}
                            </Typography>
                            <center>
                                <img src={image} alt="Something Went Wrong" width='300px' align="center" loop={false} />

                            </center>
                            <Typography variant="h1" sx={{ fontSize: 20, color: 'var(--title-color)' }} align="center">
                                {data.pageTranslationText[0].thank_you}
                            </Typography>
                            <Box height={50} />
                        </Box>

                    </center>

                </motion.div>
            </Grid>
        </Grid>


    );

}



export default Thanks
