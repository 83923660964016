import React, { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import './App.css';
import anwb from './assets/ANWB_Logo.png';
import inconnect from './assets/inconnect-icon.png';
import ANWBHome from './pages/ANWB-Home';
import Error from './pages/Error';
import Home from './pages/Home';
import InconnectHome from './pages/Inconnect-Home';
import Results from './pages/Results';
function App() {

  const [currentIcon, setCurrentIcon] = useState(inconnect);

  const [iframeURI, setIframeURI] = useState("");

  const [bgColor, setBgColor] = useState("");

  const [title, setTitle] = useState("InConnect");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const paramValue = params.get('type');

    //console.log(paramValue);

    if (paramValue === 'ANWB' || paramValue === 'anwb') {

      document.documentElement.style.setProperty('--primary-color', '#0990d8');
      document.documentElement.style.setProperty('--title-color', '#003d86');
      document.documentElement.style.setProperty('--desc-color', '#0058c2');
      setBgColor("#0096da");
      setCurrentIcon(anwb);
      setTitle("ANWB- Safety Scan");
      setIframeURI(process.env.React_App_JOTFORM_ANWB); // Set the ANWB URL

    } else {

      document.documentElement.style.setProperty('--primary-color', '#fc7c4c');
      document.documentElement.style.setProperty('--title-color', '#7b7777');
      document.documentElement.style.setProperty('--desc-color', '#7b7777');
      setBgColor("#fc7c4c");
      setCurrentIcon(inconnect);
      setTitle("InConnect");
      setIframeURI(process.env.React_App_JOTFORM_INCONNECT); // Set the InConnect URL

    }
  }, []);




  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);

  //   const paramValue = params.get('type');

  //   //console.log("HI****************");
  //   //console.log(paramValue);

  //   if (paramValue === 'inconnect') {
  //     setIframeURI("https://form.jotform.com/230540611080340"); // Set the InConnect URL
  //   } else {

  //   }
  // }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home iframeURL={iframeURI} bgColor={bgColor} />
    },
    {
      path: "/error/",
      element: <Error />
    },
    {
      path: "/result/",
      element: <Results />
    },
    {
      path: "/quick-scan/anwb",
      element: <ANWBHome />
    },
    {
      path: "/quick-scan",
      element: <InconnectHome />
    }
  ]);


  return (
    <div className="App">
      <link rel="icon" type="image/png" href={currentIcon} />
      <title>{title}</title>
      <RouterProvider router={router} />
    </div>

  );
}

export default App;
