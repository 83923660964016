import { Box, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import image from '../assets/safe_house.png';
import ProductTipsComponent from '../components/ProductsTips';
function RecommendationComponent(props) {
    const { message, data } = props.data;

    const [isHideRecommendation, setIsHideRecommendation] = useState(false);
    const [tips, setTips] = useState("");

    useEffect(() => {

        if (data.recommendation.device.isDeviceRecAvailable) {
            setTips(data.recommendation.device.info.deviceTips.map(item => (
                <motion.div
                    initial={{ clipPath: 'inset(0% 0% 100% 0%)' }}
                    animate={{ clipPath: 'inset(0% 0% 0% 0%)' }}
                    transition={{
                        duration: 0.5,
                        delay: 0.2
                    }}
                >


                    <ProductTipsComponent data={item} />
                    <Box height={30} />
                </motion.div>
            )));
        } else {
            setTips(data.recommendation.device.isDeviceRecTitle);
        }



        if (data.safetyScore.scoreNum >= 100) {
            setIsHideRecommendation(true);
        } else {
            setIsHideRecommendation(false);
        }
    }, []);


    return (

        <Grid container justifyContent="center" sx={{ minHeight: '80vh' }}>
            <Grid item xs={12} sm={10} md={8} lg={6}>
                <motion.div
                    initial={{ scale: 0.2, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.7 }}
                    style={{ perspective: "500px", perspectiveOrigin: "50% 50%", transformStyle: "preserve-3d" }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            boxShadow: '0px 0px 1px rgba(185, 185, 185, 0.5)', // Set box-shadow to red
                            minHeight: "65vh",
                            padding: 5,
                            fontFamily: 'Times',
                            textAlign: 'left',
                            display: 'table-cell'
                        }}
                    >

                        {
                            data.recommendation.device.isDeviceRecAvailable ? <Typography variant="p" sx={{ fontWeight: "bold", fontSize: 28, color: 'var(--title-color)' }} align="left" gutterBottom>
                                {data.pageTranslationText[0].happy_to_help_you}
                            </Typography> : <div></div>
                        }

                        <Box height={20} />
                        {data.recommendation.device.isDeviceRecAvailable ? <Typography variant="p" sx={{ color: 'var(--desc-color)' }} align="left" gutterBottom>
                            {data.pageTranslationText[0].happy_to_help_dec}
                        </Typography> : <div></div>}

                        <Box height={30} />

                        {
                            data.recommendation.device.isDeviceRecAvailable ? tips : <center><Typography sx={{ fontWeight: "bold", fontSize: 25, color: 'var(--title-color)' }}>{tips}</Typography></center>
                        }
                        {
                            !data.recommendation.device.isDeviceRecAvailable ?
                                <center>
                                    <img src={image} alt="Something Went Wrong" width='300px' align="center" loop={false} />
                                </center> : <div></div>
                        }
                    </Box>

                </motion.div>
            </Grid>
        </Grid>

    );
}

export default RecommendationComponent;
