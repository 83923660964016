import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Thanks from '../pages/Thank';
import RecommendationComponent from '../sections/recommendation';
import TipsComponent from '../sections/safety-tips';
import ScoreComponent from '../sections/score-new';
import BottomButtons from './bottomButton';
import Header from './Header';
const useStyles = makeStyles((theme) => ({
  body: {
    width: 500,
    backgroundColor: 'green'

  },
  root: {
    flexGrow: 1,
    backgroundColor: '#003d86',

  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    boxShadow: 'none',
  },
}));

function MyComponent(props) {
  const { message, data } = props.data;

  // //console.log(data);

  const [progress, setProgress] = React.useState(80);
  const [displayNext, setDisplayNext] = React.useState(true);
  const [displayPrev, setDisplayPrev] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);


  const screenList = [
    <ScoreComponent data={{ data: data }} />,
    <TipsComponent data={{ data: data }} />,
    <RecommendationComponent data={{ data: data }} />,
    <Thanks data={{ data: data }} />
  ];


  const handlePrev = () => {
    // //console.log(currentIndex);
    //console.log("First " + currentIndex);
    if (currentIndex >= 1) {

      setCurrentIndex((prev) => prev - 1);

      if (currentIndex === 1) {
        setDisplayPrev(false);
      }

    } else {
      setDisplayPrev(false);

    }
    //console.log("Last " + currentIndex);
    setDisplayNext(true);

  };

  const handleNext = () => {


    if (currentIndex < 3) {
      setCurrentIndex((prev) => prev + 1);
      setDisplayPrev(true);
    } else {
      setDisplayPrev(true);
    }

    if (currentIndex === 2) {
      setDisplayNext(false);
    }

    //console.log(currentIndex);
  };


  return (
    <div className={useStyles.root}>
      <Box
        sx={{
          backgroundColor: '#f2f3f5',

        }}
      >
        <Header />
        <Box height={80} />

        {screenList[currentIndex]}
        <BottomButtons onPrevClick={handlePrev} onNextClick={handleNext} isNextDisplay={displayNext} isPrevDisplay={displayPrev} prevBtnText={data.pageTranslationText[0].back_button} nextBtnText={data.pageTranslationText[0].next_button} />
        <Box height={40} />
      </Box>

    </div >
  );
}

export default MyComponent;
