
import { default as React, useEffect, useState } from 'react';
function Home(props) {
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
        document.body.style.backgroundColor = props.bgColor;
    }, [props.bgColor]);
    return (
        <div>

            {/* <iframe
                id="JotFormIFrame-230520959044454"
                allowtransparency="true"
                allowFullScreen={true}
                style={{
                    width: '100%', height: '100vh'
                }}
                sandbox="allow-same-origin allow-scripts"
                allow="geolocation; microphone; camera"
                src={props.iframeURL}
                scrolling="yes"
            >
            </iframe> */}
            < iframe
                id="JotFormIFrame-230520959044454"
                allowtransparency="true"
                allowFullScreen={true}
                style={{ height: '98vh', maxHeight: '99vh', width: '99.7%' }}
                allow="geolocation; microphone; camera"
                src={props.iframeURL}
                scrolling="yes"
                sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
            ></iframe>

        </div >
    );

}

export default Home;

