import { Box, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { React, useEffect, useState } from 'react';
import image from '../assets/safe_house.png';
import SafetyTips from '../components/SafetyTips';
function TipsComponent(props) {
    const { message, data } = props.data;

    const [isHideTitle, setIsHideTitle] = useState(false);
    const [tips, setTips] = useState("");

    useEffect(() => {

        document.body.style.backgroundColor = "#f2f3f5";

        if (data.recommendation.tips.isTipAvailable) {
            setTips(data.recommendation.tips.info.safetyCategoryTips.map(item => (
                <motion.div
                    key={item.recommendation}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <SafetyTips title={item.title} recommendation={item.recommendation} />
                    <Box height={30} />
                </motion.div>
            )));
        } else {
            setTips(data.recommendation.tips.title);
        }

        if (data.safetyScore.scoreNum >= 100) {
            setIsHideTitle(true);
        } else {
            setIsHideTitle(false);
        }
    }, []);

    return (
        <center>
            <Grid container justifyContent="center" >
                <Grid item xs={12} sm={10} md={8} lg={6}>
                    <motion.div
                        initial={{ scale: 0.2, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.7 }}
                        style={{ perspective: "500px", perspectiveOrigin: "50% 50%", transformStyle: "preserve-3d" }}
                    >
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                boxShadow: '0px 0px 1px rgba(185, 185, 185, 0.5)', // Set box-shadow to red
                                minHeight: "100vh",
                                padding: 5,
                                fontFamily: 'Times',
                                textAlign: 'left',
                                display: 'table-cell'

                            }}
                        >

                            {
                                data.recommendation.tips.isTipAvailable ? <Typography variant="p" sx={{ fontWeight: "bold", color: 'var(--title-color)' }} align="left" gutterBottom>
                                    {data.pageTranslationText[0].tip_advice}
                                </Typography>
                                    : <div></div>
                            }
                            <Box height={20} />
                            {
                                data.recommendation.tips.isTipAvailable ? tips : <center><Typography sx={{ fontWeight: "bold", fontSize: 25, color: 'var(--title-color)' }}>{tips}</Typography></center>
                            }
                            {
                                !data.recommendation.tips.isTipAvailable ?
                                    <center>
                                        <img src={image} alt="Something Went Wrong" width='300px' align="center" loop={false} />
                                    </center> : <div></div>
                            }


                        </Box>
                    </motion.div>
                </Grid>
            </Grid>
        </center>

    );
}

export default TipsComponent;
