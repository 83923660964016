import { Box, Typography } from '@mui/material';
import CircularProgress, {
    circularProgressClasses
} from '@mui/material/CircularProgress';
import { default as React, useEffect, useState } from "react";
import CountUp from "react-countup";

const CountingAnimation = ({ start = 0, end = 100, duration = 2000, prefix = "" }) => {
    const [count, setCount] = useState(start);
    const step = Math.round((end - start) / (duration / 10));
    console.log(step);
    useEffect(() => {
        const timer = setInterval(() => {
            const nextCount = count + step;
            if (step > 0 && nextCount >= end) {
                clearInterval(timer);
                setCount(end);
            } else if (step < 0 && nextCount <= end) {
                clearInterval(timer);
                setCount(end);
            } else {
                setCount(nextCount);
            }
        }, 10);
        return () => clearInterval(timer);
    }, [count, end, step]);

    return (



        <Box sx={{
            position: 'relative', display: 'inline-block',

        }}>

            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                    }}
                    size={300}
                    thickness={6}
                    value={100}
                />
                <CircularProgress
                    variant="determinate"
                    disableShrink
                    value={end}
                    sx={{
                        color: 'var(--primary-color)',
                        animationDuration: '15000ms',
                        position: 'absolute',
                        left: 0,
                        top: 0, // Add top: 0 to position the component at the top
                        transform: 'translate(50%, 50%)', // Center the component using transform
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }}
                    size={300}
                    thickness={6}
                />
            </Box>
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >

                <Typography variant="h2" component="h2" align="center">
                    {/* {prefix}
                    {count}% */}
                    <CountUp start={0} end={end} duration={4} />%
                </Typography>
            </Box>
        </Box>
    );
};

export default CountingAnimation;
