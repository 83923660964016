import { Box, Typography } from '@mui/material';
import ImageComponent from '../components/ImageComponent';
import React from 'react';



function SafetyTips(props) {
    const { recommendation, title } = props;

    return (
        <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold", color: 'var(--title-color)' }} align="left" gutterBottom>
            <ImageComponent/>    {title}
            </Typography>
            <Typography variant="p" align="left" sx={{ color: 'var(--desc-color)' }} gutterBottom>
                {recommendation}
            </Typography>
        </Box>
    );
}

export default SafetyTips;
