
import { AppBar, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import anwb from '../assets/ANWB_Logo.png';
import inconnect from '../assets/inconnect-logo.png';
const Header = () => {

    const [currentIcon, setCurrentIcon] = useState(inconnect);

    const [headerText, setHeaderText] = useState("");



    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        const paramValue = params.get('type');

        const language = params.get("language")


        if (paramValue === 'ANWB' || paramValue === 'anwb') {
            setCurrentIcon(anwb);
        } else {
            setCurrentIcon(inconnect);
        }

        if (language === "en") {
            if (paramValue === 'ANWB' || paramValue === 'anwb') {
                setHeaderText("Safe at Home scan");
            } else {
                setHeaderText("Quick Scan");
            }

        } else {
            if (paramValue === 'ANWB' || paramValue === 'anwb') {
                setHeaderText("Veilig Thuis scannen");
            } else {
                setHeaderText("Quick Scan");
            }
        }

    }, []);


    return (
        <AppBar elevation={1} position="fixed" sx={{ backgroundColor: '#ffffff' }}>
            <Toolbar >
                <img src={currentIcon} width={80} alt="ANWB LOGO" />
                <Typography variant="h6" sx={{ color: 'var(--primary-color)', fontFamily: 'Segoe UI', fontSize: 18, margin: '0 0 0 10px' }} component="div">
                    {headerText}
                </Typography>
            </Toolbar>
        </AppBar >
    );
};

export default Header;
