import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import CountUp from "react-countup";




function ProgressRow({ percentage, type }) {

    return (
        <Box display="flex" alignItems="center">
            <Box minWidth={80}>
                <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'var(--title-color)' }} >{`${type}`}</Typography>
            </Box>
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" size="lg" sx={{ animationDuration: '8000ms', height: 25, borderRadius: 10, '& .MuiLinearProgress-bar': { backgroundColor: 'var(--primary-color)' } }} value={percentage} thickness={6} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'var(--title-color)' }} color="textSecondary"><CountUp start={0} end={Math.round(
                    percentage
                )} duration={8} />%</Typography>
            </Box>
            <Box height={35}>

            </Box>
        </Box>
    );
}

export default ProgressRow;
